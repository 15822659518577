<template>

    <div>
        <div v-if="items.length" class="overflow-y-scroll max-h-128 hide-scroll dark:shadow-none shadow-md my-10">
            <table class=" w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm ">
                <thead class="dark:bg-gray-800 bg-white text-gray-500">
                <th class="p-3 select-none">№</th>
                <th class="p-3 select-none" v-for="(header, idx) in headers" :key="'header-'+header.value + '_'+ idx"
                >{{ header.name }}
                </th>
                <th class="p-3 select-none">
                    Распознать платеж
                </th>


                </thead>
                <tbody class="dark:text-gray-400 text-gray-600">
                <tr class="dark:bg-gray-800 bg-white cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300 "
                    v-for="(data, idx) in items" :key="'client-'+data.id"
                >
                    <td class="p-3">{{ idx + 1 }}</td>

                    <td class="p-3 " :key="header.value + '_'+ idx" v-for="(header, idx) in headers">

                        <span> {{ data[header.value] }}</span>
                    </td>

                    <td class="text-center">
                        <button class="p-3" @click="selectCompany(data)">Выбрать компанию</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <h3 class="text-2xl text-center w-full p-10">
                Список платежей пуст
                <g-emoji>😥</g-emoji>
            </h3>
        </div>
    </div>


</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ManagePaymentsTable",
    props: {
        headers: {
            required: true,
            type: Array
        }
    },
    computed: {
        ...mapGetters({
            items: 'clientPayments/unresolved'
        })
    },
    methods: {
        selectCompany(payment) {

            this.$emit('select', payment)
        }
    }

}
</script>

<style scoped>

</style>