<template>
<div>
    <ManagePaymentsTable :headers="headers" @select="openModal"></ManagePaymentsTable>

    <ManagePaymentsModal :headers="headers" :showModal.sync="showModal" :payment="payment"></ManagePaymentsModal>

</div>
</template>

<script>
import ManagePaymentsTable from '@/components/user/admin/payments/ManagePaymentsTable'
import ManagePaymentsModal from '@/components/user/admin/payments/ManagePaymentsModal'

export default {
    name: "PaymentResolve",
    components: {
        ManagePaymentsTable, ManagePaymentsModal
    },
    data: () => ({
        payment: {},
        showModal: false,
        headers: [
            {name: 'Номер платежа', value: 'paymentNumber'},
            {name: 'Название организации', value: 'orgName'},
            {name: 'УНП', value: 'orgUnp'},
            {name: 'Сумма', value: 'sum'},
            {name: 'Назначение платежа', value: 'paymentPurpose'},
            {name: 'Описание ошибки', value: 'description'},
            {name: 'Дата', value: 'updated'},
        ]
    }),
    created() {
        this.$store.dispatch('clientPayments/getUnresolved')

    },
    methods: {
        openModal(payment) {
            this.payment = payment
            this.showModal = true
        }
    }

}
</script>

<style scoped>

</style>