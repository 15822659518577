<template>
    <Modalwindow v-show="showModal" @close="closeModal" class="overflow-auto">


        <div
            class="lg:w-1/2 sm:w-full min-h-5/6 dark:bg-black bg-white dark:text-white text-black  p-10 rounded relative lg:border-l-2 ">
            <div class="flex justify-between  w-full">
                <h2 class="text-2xl">{{ title }}</h2>
                <CloseButton class=" absolute top-5 right-5" @close="closeModal"></CloseButton>
            </div>
            <div class="py-5" v-show="!check">
                <div class="flex filter mx-0.5">
                    <div><input type="text" placeholder="Юр.лицо" v-model="filter.uf_name"></div>
                    <div><input type="text" placeholder="УНП" v-model="filter.uf_unp"></div>
                    <div><input type="text" placeholder="Договор" v-model="filter.uf_contract"></div>
                    <div>
                        <button class="h-full w-full bg-blue-400" @click="unset">Сбросить</button>
                    </div>
                </div>
                <div class="h-128 overflow-y-scroll my-0.5">
                    <table>
                        <tbody>
                        <tr class="cursor-pointer"
                            :class="{'dark:bg-gray-800 bg-white  dark:hover:bg-gray-700 hover:bg-gray-300': org.id !== organization.id,
                        'bg-green-500 text-white': org.id === organization.id
                        }"
                            v-for="org in filteredOrgs" :key="'org-'+org.id"
                            @click="selectOrganization(org)"
                        >
                            <td class="p-3 ">{{ org.uf_name }}</td>
                            <td class="p-3 ">{{ org.uf_unp }}</td>
                            <td class="p-3 ">{{ org.uf_contract }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <button :disabled="!isOrgSelected" @click="nextStep"
                        :class="{'bg-blue-400':isOrgSelected,'bg-gray-500 cursor-default':!isOrgSelected}"
                        class="ml-0.5 px-5 text-white  py-2.5 rounded mt-5">Далее
                </button>
            </div>
            <div v-show="check" class="py-5">
                <button @click="prevStep"><i class="fas fa-arrow-left"></i><span class="px-2">Выбор организации</span>
                </button>
                <div class="mt-5 sm:overflow-x-auto">
                    <table>
                        <thead>
                        <th class="p-3 select-none" v-for="(header, idx) in headers"
                            :key="'header-'+header.value + '_'+ idx">{{ header.name }}
                        </th>
                        </thead>
                        <tbody>
                        <tr class="dark:bg-gray-800 bg-white select-none ">
                            <td class="p-3 " :key="header.value + '_'+ idx" v-for="(header, idx) in headers">
                                <span> {{ payment[header.value] }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center text-lg my-5">
                    применить к
                </div>
                <div class="sm:overflow-x-auto">
                    <table class="mb-10">
                        <thead>
                        <th class="p-3 select-none">Юр.лицо</th>
                        <th class="p-3 select-none">УНП</th>
                        <th class="p-3 select-none">Договор</th>
                        </thead>
                        <tbody>
                        <tr class="dark:bg-gray-800 bg-white select-none">
                            <td class="p-3 ">{{ organization.uf_name }}</td>
                            <td class="p-3 ">{{ organization.uf_unp }}</td>
                            <td class="p-3 ">{{ organization.uf_contract }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="warning bg-yellow-500 p-3 text-sm border-l-4" v-show="warnings.length">
                    <p v-for="(w, idx) in warnings" :key="'w'+idx">{{ w }}</p>
                </div>
                <div class="flex my-10 confirm">
                    <p class="select-none w-1/2 my-auto">Для подтверждения операции введите указанный код: <span
                        class="text-blue-500">{{ code_text }}</span></p>
                    <input type="text" v-model="code" placeholder="Код подтверждения">
                </div>
                <div class="flex justify-between">
                    <button class="px-5 py-2.5 rounded text-white" :disabled="!confirmed" @click="submit"
                            :class="{'bg-green-400 cursor-pointer':confirmed,'bg-gray-500 cursor-default':!confirmed}">
                        Сохранить
                    </button>
                    <button class="px-5 py-2.5 rounded text-white bg-red-600" @click="closeModal">Отменить</button>
                </div>

            </div>
        </div>


    </Modalwindow>
</template>

<script>
import Modalwindow from '@/components/common/ModalWindow'
import CloseButton from '@/components/common/CloseButton'
import {mapGetters} from "vuex";

export default {
    name: "ManagePaymentsModal",
    components: {
        Modalwindow, CloseButton
    },
    data: () => ({
        organization: {},
        check: false,
        code: null,
        code_text: 0,
        filter: {
            uf_name: '',
            uf_unp: '',
            uf_contract: ''
        },
    }),
    computed: {
        ...mapGetters({
            organizations: 'admin/contractsSimple'
        }),
        filteredOrgs() {
            let items = this.organizations
            let filter = {}
            for (let key in this.filter) {
                if (this.filter[key].length) {
                    filter[key] = this.filter[key]
                }
            }
            if (Object.keys(filter).length) {
                for (let key in filter) {
                    items = items.filter(i => {
                        if (i[key] && i[key] !== null) {
                            return i[key].toLowerCase().includes(filter[key].toLowerCase())
                        }

                    })
                }
                return items
            } else {
                return this.organizations
            }

        },
        isOrgSelected() {
            return Object.hasOwnProperty.call(this.organization, 'id') && this.organization.id > 0;
        },
        title() {
            if (this.check === false) {
                return 'Выберите организацию'
            } else {
                return 'Проверка данных'
            }
        },
        confirmed() {
            return Number.parseInt(this.code) === Number.parseInt(this.code_text);
        },
        warnings() {
            let warnings = []
            if (Object.keys(this.payment).length && Object.keys(this.organization).length) {
                if (this.payment.orgUnp.toString().trim() !== this.organization.uf_unp.toString().trim()) {
                    warnings.push('УНП платежа и УНП организации различаются!')
                }
                if (this.payment.orgName.trim().toLowerCase() !== this.organization.uf_name.trim().toLowerCase()) {
                    warnings.push('Названия организаций не совпадают!')
                }
                if (this.payment.paymentPurpose.indexOf(this.organization.uf_contract)  ===  -1) {
                    warnings.push('Номера договоров не совпадают!')
                }
            }

            return warnings
        }
    },
    props: {
        payment: {
            required: true,
            type: Object
        },
        showModal: {
            required: true,
            type: Boolean
        },
        headers: {
            required: true,
            type: Array
        }

    },
    methods: {
        closeModal() {
            this.organization = {}
            this.check = false
            this.code_text = ''
            this.code = null
            this.$store.dispatch('clientPayments/getUnresolved')
            this.$emit('update:showModal', false)
        },
        selectOrganization(org) {
            if (this.organization.id === org.id) {
                this.organization = ''
            } else {
                this.organization = org
            }

        },
        unset() {
            this.filter = {
                uf_name: '',
                uf_unp: '',
                uf_contract: ''
            }
        },
        nextStep() {
            this.check = true
            this.code_text = this.getRandomInt(1000, 9999)
        },
        prevStep() {
            this.check = false
            this.code_text = ''
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            let int = Math.floor(Math.random() * (max - min)) + min;
            return int
        },
        submit() {
            let payload = {
                payment: this.payment.id,
                unp: this.organization.uf_unp
            }
            this.$store.dispatch('clientPayments/updateUnresolved', payload).then(res => {
                this.$notify({
                    title: 'Успешно',
                    text: res.data.data,
                    type: 'success'
                })
                this.$store.dispatch('clientPayments/getUnresolved')
                this.closeModal()
            }).catch(err => {
                this.$notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        }
    }
}
</script>

<style scoped>
table {
    @apply w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm;
}

thead {
    @apply dark:bg-gray-800 bg-white text-gray-500
}

tbody {
    @apply dark:text-gray-400 text-gray-600
}

.filter div {
    @apply w-1/4 mr-1;
}

input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}

.confirm input {
    @apply w-1/2;
}
</style>